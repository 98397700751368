.TryoutContainer {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.tryout-content {
    background-color: #2B2D42;
    padding: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    text-align: right;
    flex-wrap: wrap;
    direction: rtl;
}

.tryout-content p {
    font-size: 24px;
    margin: 0;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    background-image: linear-gradient(90deg, #5A38FF, #01D2A4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin: 0;
    width: 100%;
    max-width: 400px;
    word-break: break-word;
    line-height: 1.2;
}

@media (max-width: 768px) {
    .tryout-content p {
        font-size: 18px;
        max-width: 100%;
    }
}

.tryout-buttons {
    display: flex;
    gap: 10px;
}

.get-started-button {
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    color: #f0f0f0;
    background-color: #3d3f57;
    border: none;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 12px;
    cursor: pointer;
    transition: background 0.3s;
    padding: 10px;
    width: 150px;
}

.get-started-button:hover {
    background-color: #9E8AFF;
    color: #5A38FF;
}