.privacy-policy-container {
    width: 80%;
    margin: 0 auto;
    margin-top: 60px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 25px;
    direction: rtl;
    text-align: right;
    margin-bottom: 40px;
}

.title-pr {
    font-family: "Rubik", sans-serif;
    font-weight: 800;
    text-align: right;
    font-size: 20px;
    margin-top: 40px;
}

.sub-pr {
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    text-align: right;
    font-size: 16px;
}

.sub-pr2 {
    font-family: "Rubik", sans-serif;
    font-weight: 800;
    text-align: right;
    font-size: 18px;
}

.content-pr {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    text-align: right;
    color: #2B2D42;
    font-size: clamp(8px, 2vw, 12px);
}

.up-date {
    display: flex;
    justify-content: space-between;
}

.date-pr {
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    text-align: right;
    color: #c5c5c5;
    font-size: 10px;
}