@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

.App {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background-color: #ffffff;
}

::selection {
  color: #2b2d42;
  background-color: #9e8aff;
}

.Rubik-light {
  font-family: "Rubik", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.Rubik-regular {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.Rubik-semiBold {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.Rubik-bold {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.Rubik-extrabold {
  font-family: "Rubik", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 60px;
  height: 60px;
  display: block;
  margin: 0 auto;
}

.footer {
  color: #2b2d42;
  padding: 20px;
  margin: 0 auto;
  width: 84%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 10px;
  flex-wrap: wrap-reverse;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  margin-top: 10px;
  color: #2b2d42;
  background-color: #f5f5f5;
  border-radius: 25px;
  padding: 14px;
}

.copyright {
  margin: 0;
  flex-grow: 1;
  text-align: left;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  color: #2b2d42;
  font-size: 10px;
}

.xlogo {
  width: 30px;
  height: 30px;
}

.footer-link {
  color: #2b2d42;
  padding: 8px;
}

.footer a {
  color: #2b2d42;
  text-decoration: none;
  font-size: 10px;
}

.footer a:hover {
  color: #5a38ff;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    padding: 10px;
    max-width: 100%;
  }

  .copyright {
    text-align: center;
    order: 2;
  }
}

hr {
  width: 90%;
  border: 0;
  border-bottom: 1px solid #ccc;
  margin: 20px auto;
  margin-bottom: 4px;
}

.cta2-button {
  font-family: "Rubik", sans-serif;
  font-weight: 800;
  color: #2b2d42;
  background-color: #b0ffe2;
  border: none;
  border-radius: 10px;
  padding: 1rem 2rem;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s;
  width: 220px;
}

.cta2-button:hover {
  background-color: #9e8aff;
  color: #5a38ff;
}

.payment-container {
  width: 90%;
  max-width: 400px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
