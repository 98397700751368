.subscription-container {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.email-subscription {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 24px;
}

.email-subscription h2 {
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    color: #2b2d42;
    font-weight: 700;
    margin-bottom: 4px;
    text-align: center;
    width: 100%;
}

.email-form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    height: 44px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 5px 10px;
    box-sizing: border-box;
}

.email-input {
    border: none;
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #2b2d42;
    background-color: transparent;
    padding: 0 10px;
    height: 100%;
    line-height: 4px;
    box-sizing: border-box;
    width: 100%;
}

.email-input::placeholder {
    color: #2b2d42;
    opacity: 0.7;
}

.subscribe-btn {
    background-color: #5a38ff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    font-size: 12px;
    width: 60%;
}

.subscribe-btn:hover {
    background-color: #9E8AFF;
    color: #5A38FF;
}
