.contact-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 25px;
    direction: rtl;
    text-align: right;
    max-width: 400px;
    font-family: "Rubik", sans-serif;
}

.contact-page {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    padding: 20px;
    margin-bottom: 60px;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    height: auto;
}

.form-toggle-buttons {
    display: flex;
    height: 40px;
    justify-content: flex-start;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
}

.form-toggle-buttons button {
    margin: 0 10px;
    padding: 10px 10px;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    font-size: 12px;
    width: 50%;
    border-radius: 25px;
    border: none;
}

.re-btn {
    color: #ec7571;
    background-color: #F3F3F3;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
}

.co-btn {
    color: #2B2D42;
    background-color: #F3F3F3;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
}

.re-btn:hover {
    color: #ec7571;
    background-color: #fad6d4;
}

.co-btn:hover {
    color: #2B2D42;
    background-color: #B0FFE2;
}

.ch-msg {
    text-align: right;
    margin-bottom: 20px;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
}

.msge-title {
    text-align: right;
    margin-bottom: 10px;
    color: #2B2D42;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
}

.msge-sub {
    text-align: right;
    color: #848484;
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
    max-width: 400px;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
}

.form-group input,
textarea {
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    width: 100%;
    padding: 4px;
    height: 48px;
    border: none;
    border-radius: 12px;
    font-size: 12px;
}

textarea {
    resize: none;
    height: 100px;
}

.success-message {
    font-family: "Rubik", sans-serif;
    font-weight: 800;
    font-size: 12px;
    color: #33cc99;
    background-color: #B0FFE2;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    padding: 4px;
}

.sun-btn{
    font-family: "Rubik", sans-serif;
    font-weight: 800;
    font-size: 14px;
    background-color: #fafafa;
    color: #5A38FF;
    border: none;
    padding: 4px;
    border-radius: 4px;
    width: 100%;
}

.sun-btn:hover{
    background-color: #9E8AFF;
}