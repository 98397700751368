.Featuers-section {
    text-align: right;
    padding: 50px;
    background-color: #FFFFFF;
    margin-bottom: 40px;
}

.main-title {
    font-size: 24px;
    color: #2A2630;
    margin-bottom: 10px;
    font-family: "Rubik", sans-serif;
    font-weight: 800;
    text-align: right;
    margin-bottom: 40px;
}

.main-subtitle {
    font-size: clamp(0.75rem, 1vw, 12px);
    color: #b1b1b1;
    margin-bottom: 40px;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    text-align: right;
}

.highlight-link {
    color: #33cc99;
    font-weight: 800;
}

.Featuers-container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 20px;
}

.Featuers-card {
    background-color: #F6F6F7;
    border-radius: 8px;
    width: calc(50% - 20px);
    min-width: 300px;
    height: auto;
    text-align: right;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
}

.Featuers-card h2 {
    font-size: 18px;
    color: #2A2630;
    margin-bottom: 10px;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    text-align: right;
}

.Featuers-card p {
    font-size: 10px;
    color: #666;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
}

