.faq-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    text-align: right;
    background-color: #f9f9f9;
    border-radius: 25px;
    margin-bottom: 40px;
}

.faq-title {
    text-align: center;
    margin-bottom: 40px;
    color: #2B2D42;
    font-family: "Rubik", sans-serif;
    font-weight: 800;
}

.faq-item {
    cursor: pointer;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.faq-icon {
    font-size: 18px;
    font-weight: 400;
    color: #c5c5c5;
    transition: color 2s ease;
}

.faq-question-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 20px;
}

.faq-question {
    font-size: clamp(12px, 2vw, 16px);
    color: #2B2D42;
    transition: color 0.3s ease;
    font-family: "Rubik", sans-serif;
    font-weight: 600;
}

.faq-question:hover {
    color: #5A38FF;
}

.faq-answer {
    font-size: 12px;
    color: #555;
    transition: max-height 0.3s ease;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    margin: 20px;
}

.faq-answer.expanded {
    max-height: 200px;
    margin-bottom: 20px;
}
