/* MainSection.css */

.main-section-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    justify-content: center;
    height: 100vh;
    margin-bottom: 100px;
}

.main-section {
    text-align: center;
    padding: 2rem;
    margin-top: 8%;
}

.cursor {
    color: #33cc99;
}

.betaMain {
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    color: #5A38FF;
    background-color: #f4f3fa;
    padding: 4px;
    font-size: 12px;
    border-radius: 4px;
}

.cora-text {
    font-family: "Rubik", sans-serif;
    font-weight: 800;
    font-size: 3rem;
    color: #2B2D42;
}


.dis-main {
    font-size: clamp(28px, 2vw, 44px);
    color: #2B2D42;
    margin: 1rem;
    font-family: "Rubik", sans-serif;
    font-weight: 800;
    text-align: center;
    overflow: hidden;
    min-height: 2.5rem;
    margin-bottom: 0px;
}

.small-desc {
    font-size: clamp(12px, 2vw, 14px);
    color: #b1b1b1;
    font-family: "Rubik", sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
}



.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    text-align: center;
    direction: rtl;
    width: 100%;
}

.con-button {
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    color: #2B2D42;
    background-color: #efefef;
    border: none;
    border-radius: 8px;
    padding: 0 2rem;
    font-size: 12px;
    cursor: pointer;
    transition: background 0.3s;
    height: 34px;
}

.con-button:hover {
    color: #5A38FF;
}

.cta-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2B2D42;
    color: #efefef;
    font-family: "Rubik", sans-serif;
    padding: 10px 16px;
    border-radius: 8px;
    transition: all .3s ease;
    font-weight: bold;
    cursor: pointer;
    font-size: 12px;
    border: none;
    min-width: 180px;
    position: relative;
    height: 34px;
}

.cta-button>.arrow {
    width: 6px;
    height: 6px;
    border-left: 2px solid #efefef;
    border-top: 2px solid #efefef;
    position: absolute;
    left: 16px;
    transform: rotate(-45deg);
    margin: 0 6px;
    transition: all .3s ease;
}

.cta-button>.arrow::before {
    display: block;
    background-color: currentColor;
    width: 3px;
    height: 2px;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
    transition: all .3s ease;
    content: "";
    opacity: 0;
}

.cta-button:hover>.arrow {
    transform: rotate(-45deg) translate(-4px, -4px);
    border-color: currentColor;
}

.cta-button:hover>.arrow::before {
    opacity: 1;
    width: 8px;
}

.cta-button:hover {
    background-color: #9E8AFF;
    color: #5A38FF;
}


.video {
    width: 300px;
    margin: 0 auto;
    pointer-events: none;
    object-fit: cover;
}