.CoraPay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  height: 100%;
  font-family: "Rubik", sans-serif;
}

.CoraPay-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 20px;
  height: 100%;
  margin-bottom: 100px;
  overflow-y: auto;
  padding: 20px;
  font-family: "Rubik", sans-serif;
}

.CoraPay-checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 20px;
  height: auto;
  padding: 20px;
  overflow-y: visible;
  margin-bottom: 100px;
  min-height: 100vh;
}

.CoraPay-main::-webkit-scrollbar {
  display: none;
}

.CoraPayForm {
  background: #fff;
  border-radius: 4px;
  width: 100%;
  max-width: 320px;
  text-align: center;
}

.CoraPay h2 {
  margin-bottom: 40px;
  font-size: 24px;
  color: #2b2d42;
  text-align: right;
  font-family: "Rubik", sans-serif;
}

.title-top {
  margin-bottom: 40px;
  font-size: 24px;
  color: #2b2d42;
  text-align: right;
  font-family: "Rubik", sans-serif;
}

.CoraPay p {
  margin-bottom: 20px;
  font-size: 12px;
  color: #e1e1e1;
  text-align: right;
}

.otp-header {
  font-size: 24px;
  color: #2b2d42;
  text-align: right;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
}

.CoraPay-form-group {
  margin-bottom: 16px;
  text-align: right;
  position: relative;
  min-height: 85px;
}

.CoraPay-form-group label {
  display: block;
  margin-bottom: 4px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  color: #2b2d42;
  font-size: 14px;
}

.CoraPay-label {
  display: block;
  margin-bottom: 0px;
  margin-top: 10px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  color: #2b2d4255;
  font-size: 14px;
  text-align: right;
  width: 100%;
}

.CoraPay-form-group input {
  width: 100%;
  min-width: 320px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.CoraPay-form-group input:focus {
  border-color: #5a38ff;
  outline: none;
}

.CoraPay-MainButton {
  background-color: #5a38ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 4px;
  width: 100%;
  max-width: 320px;
  transition: background-color 0.3s;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
}

.CoraPay-MainButton:hover {
  background-color: #472ec1;
}

.CoraPay-MainButton[type="button"] {
  background-color: #fff;
  color: #2b2d42;
}

.CoraPay-MainButton[type="button"]:hover {
  background-color: #f7f7f7;
}
.CoraPay-MainButton:disabled {
  /* opacity: 0.6; */
  cursor: not-allowed;
}
.CoraPay-OtpButton {
  background-color: #5a38ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 4px;
  width: 100%;
  max-width: 150px;
  transition: background-color 0.3s;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
}

.CoraPay-OtpButton:hover {
  background-color: #472ec1;
}

.CoraPay-OtpButton:disabled {
  /* opacity: 0.6; */
  cursor: not-allowed;
}
.CoraPay-SecButton {
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  margin-top: 4px;
  width: 100%;
  max-width: 320px;
  transition: background-color 0.3s;
  background-color: #fdfdfd;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.CoraPay-SecButton:hover {
  background-color: #fdfdfd;
}

.CoraPay-SecButton[type="button"] {
  color: #2b2d42;
}

.CoraPay-balance-card {
  background: #fafafa;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 375px;
  top: 0;
}

.CoraPay-balance-logo {
  width: 34px;
  height: 34px;
  margin-right: 12px;
}

.CoraPay-balance-card h3 {
  margin: 0;
  font-size: 18px;
  color: #2b2d42;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.CoraPay-balance-card p {
  margin: 0;
  font-size: 12px;
  color: #dcdcdc;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.CoraPay-list {
  background: #fafafa;
  padding: 20px;
  border-radius: 12px;
  align-items: center;
  width: 100%;
  max-width: 375px;
  top: 0;
  font-family: "Rubik", sans-serif;
}

.CoraPay-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 375px;
  padding: 10px 15px;
  margin-bottom: 10px;
  background: #fafafa;
  border-radius: 8px;
  box-sizing: border-box;
}

.CoraPay-list-item:hover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 375px;
  padding: 10px 15px;
  margin-bottom: 10px;
  background: #c7d0ff;
  border-radius: 8px;
  box-sizing: border-box;
}

.CoraPay-item-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.CoraPay-item-details {
  text-align: right;
}

.CoraPay-item-details h4 {
  margin: 0;
  font-size: 14px;
  color: #2b2d42;
  font-weight: 600;
}

.CoraPay-item-details p {
  margin: 4px 0;
  font-size: 12px;
  color: #555;
  font-weight: 400;
}

.CoraPay-more {
  position: relative;
}

.CoraPay-dots {
  background: none;
  border: none;
  font-size: 22px;
  cursor: pointer;
}

.CoraPay-dropdown {
  display: none;
  position: absolute;
  top: 20px;
  right: 0;
  background: #fff;
  border: 0.5px solid #ddd;
  border-radius: 4px;
  z-index: 1000;
  padding: 20px;
}
.CoraPay-dropdown.visible {
  display: block;
}
/* .CoraPay-dots:focus + .CoraPay-dropdown,
.CoraPay-dots:hover + .CoraPay-dropdown {
  display: block;
} */
.CoraPay-edit-btn {
  display: block;
  width: 100%;
  padding: 8px 12px;
  color: #2b2d42;
  border: none;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  margin-bottom: 8px;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.CoraPay-edit-btn:hover {
  background-color: #9e8aff;
  color: #5a38ff;
}

.CoraPay-cancel-btn {
  display: block;
  width: 100%;
  padding: 8px 12px;
  color: #2b2d42;
  border: none;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.CoraPay-cancel-btn:hover {
  background-color: #ffd4d5;
  color: #ff005e;
}

.CoraPay-item-left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.CoraPay-item-status {
  font-size: 14px;
  color: #2b2d42;
  margin: 0;
  font-family: "Rubik", sans-serif;
}

.CoraPay-copy-btn {
  padding: 8px;
  background: #ededed;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoraPay-copy-btn:hover {
  background: #6a81fb;
}

.CoraPay-icon {
  width: 16px;
  height: 16px;
}

.CoraPay-item-status.active {
  color: #01d2a4;
  background-color: #d1ffeb;
  padding: 4px 20px;
  border-radius: 8px;
  font-size: 12px;
}

.CoraPay-item-status.canceled {
  background-color: #ffd4d5;
  color: #ff005e;
  padding: 4px 20px;
  border-radius: 8px;
  font-size: 12px;
}

.CoraPay-item-status.pending {
  background-color: #fff3bf;
  color: #ff9a6a;
  padding: 4px 20px;
  border-radius: 8px;
  font-size: 12px;
}

.CoraPay-button-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.CoraPay-link-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  margin-top: 20px;
  color: #2b2d42;
  background: #fafafa;
  border: none;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.CoraPay-link-btn:hover {
  color: #ffffff;
  background: #6a81fb;
}

.CoraPay-link-btn img {
  width: 24px;
  height: 24px;
  margin-bottom: 8px;
  object-fit: contain;
}

.CoraPay-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 375px;
  background: #fafafa;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1000;
  padding: 14px;
  border-radius: 12px;
  margin-bottom: 8px;
}

.CoraPay-footer-btn {
  background: none;
  border: none;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  color: #2b2d42;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.CoraPay-footer-btn i {
  font-size: 18px;
  margin-bottom: 4px;
}

.CoraPay-footer-btn:hover {
  color: #6a81fb;
}

.CoraPay-footer-btn.active {
  color: #5a38ff;
}

@media (max-width: 600px) {
  .CoraPay-footer {
    max-width: 320px;
  }
}

.Profile-options {
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
}

.Profile-option {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  cursor: pointer;
  background: #fafafa;
  border-radius: 12px;
  margin-top: 4px;
}

.Profile-option span {
  font-size: 14px;
  color: #2b2d42;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
}

.Profile-label {
  font-size: 12px;
  color: #555;
  margin-right: auto;
  margin-left: 20px;
  text-align: left;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.Profile-bank-form {
  padding: 16px;
  background: #fafafa;
  border-radius: 8px;
  margin-top: 10px;
}

.Profile-bank-form form {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: right;
}

.Profile-bank-form label {
  font-size: 12px;
  color: #333;
  margin-bottom: 0px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.Profile-bank-form input {
  padding: 10px;
  font-size: 10px;
  border: 1px solid #fafafa;
  border-radius: 8px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.Profile-bank-form button {
  padding: 10px;
  font-size: 14px;
  color: #fff;
  background-color: #5a38ff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  margin-top: 14px;
}

.Profile-bank-form button:hover {
  background-color: #472ec1;
}

.Profile-label.active-status {
  color: #01d2a4;
  background-color: #d1ffeb;
  padding: 4px 20px;
  border-radius: 8px;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  text-align: center;
}
.Profile-label.unactive-status {
  color: #ff3b30; /* Red color for unactive */
  background-color: #ffebeb; /* Light red background */
  padding: 4px 20px;
  border-radius: 8px;
  font-size: 12px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  text-align: center;
}

.Pass-change {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  cursor: pointer;
  background: #fafafa;
  border-radius: 12px;
  margin-top: 24px;
  font-size: 14px;
  color: #2b2d42;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
}

.logout {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  cursor: pointer;
  background: #fafafa;
  border-radius: 12px;
  margin-top: 4px;
  font-size: 14px;
  color: #2b2d42;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  color: #ff005e;
}

.Payment-link-header {
  width: 100%;
  margin-bottom: 20px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  text-align: right;
  box-sizing: border-box;
}

.Payment-product-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}

.link-input {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-align: right;
  margin-top: 4px;
}

.form-label {
  font-size: 14px;
  color: #2b2d42;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  text-align: right;
  margin-bottom: -4px;
}

.Payment-product-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-sizing: border-box;
  gap: 10px;
}

.Payment-product-item {
  display: flex;
  flex-direction: column;
  background: #fafafa;
  border: 1px solid #fafafa;
  border-radius: 12px;
  padding: 8px;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}

/* Remove Button */
.Payment-remove-btn {
  width: 80px;
  font-size: 10px;
  background-color: #ffd4d5;
  color: #ff005e;
  border: none;
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 10px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}

.Payment-remove-btn:hover {
  background: #ff005e;
  color: #ffd4d5;
}

.Link-add-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.Link-add-btn {
  background: #5a38ff;
  color: #fff;
  font-size: 24px;
  width: 100px;
  height: 22px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Link-add-btn:hover {
  background: #472ec1;
}

.Payment-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

.Payment-terms-text {
  font-size: 8px;
  color: #555;
  margin-top: 8px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0px;
}

.Payment-generate-btn {
  width: 100%;
  max-width: 320px;
  min-width: 290px;
  padding: 8px;
  font-size: 14px;
  background: #5a38ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
}

.Payment-generate-btn:hover {
  background: #472ec1;
}

.Payment-sheet {
  position: fixed;
  width: 100%;
  max-width: 375px;
  background: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  display: none;
  bottom: -100%;
}

.Payment-sheet.active {
  bottom: 0;
}

.Payment-sheet-label {
  font-size: 16px;
  font-weight: 600;
  color: #2b2d42;
  margin-bottom: 8px;
}

.Payment-link {
  font-size: 14px;
  color: #5a38ff;
  word-wrap: break-word;
  margin-bottom: 16px;
}

.Payment-sheet-info {
  font-size: 12px;
  color: #555;
  margin-bottom: 20px;
}

.Payment-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.Payment-product-image {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Payment-image-input {
  font-size: 0;
  text-align: center;
  background-color: #fafafa;
  border-radius: 4px;
  width: 100%;
  height: auto;
  cursor: pointer;
  border: none;
  outline: none;
  position: relative;
  overflow: hidden;
}

/* Text inside the button (hidden) */
.Payment-image-input::file-selector-button {
  content: "Upload";
  font-size: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #2b2d4291;
  background-color: #fafafa;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Payment-image-input::before {
  content: "";
  color: transparent;
}

.LinkGenerate-header {
  text-align: center;
  margin-bottom: 20px;
}

.LinkGenerate-icon img {
  width: 180px;
  height: 100px;
  margin-bottom: 10px;
}

.LinkGenerate-content {
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 20px;
}

.LinkGenerate-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  padding: 16px;
  border-radius: 8px;
}

.LinkGenerate-link {
  font-size: 14px;
  color: #5a38ff;
  flex-grow: 1;
  margin-top: 14px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
}

.LinkGenerate-info {
  font-size: 10px;
  color: #555;
  margin-top: 8px;
  text-align: center;
}

.LinkGenerate-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  padding: 0 20px;
}

.LinkGenerate-main-btn {
  padding: 10px;
  font-size: 12px;
  background: #fafafa;
  color: #2b2d42;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  width: 100%;
}

.LinkGenerate-main-btn:hover {
  color: #5a38ff;
}

/* Title */
.Checkout-title {
  font-size: 24px;
  color: #2b2d42;
  margin-top: 20px;
  margin-bottom: 40px;
  font-family: "Rubik", sans-serif;
}

.Checkout-products {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  font-family: "Rubik", sans-serif;
  text-align: right;
}

.Checkout-product {
  display: flex;
  align-items: center;
  gap: 15px;
  background: #fafafa;
  padding: 10px;
  border-radius: 12px;
}

.Checkout-product-checkbox {
  transform: scale(1.2);
  cursor: pointer;
  accent-color: #5a38ff;
}

.Checkout-product-checkbox:checked {
  background-color: #5a38ff;
  border-color: #5a38ff;
}

.Checkout-product-details {
  gap: 10px;
  align-items: center;
  width: 100%;
}

.Checkout-product-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}

.Checkout-product-name {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #2b2d42;
}

.Checkout-product-description {
  font-size: 10px;
  font-weight: 400;
  color: #555;
  cursor: pointer;
  word-break: break-word;
  white-space: normal;
  text-align: right;
  direction: rtl;
}

/* Full description */
.Checkout-full-description {
  font-size: 10px;
  font-weight: 400;
  color: #555;
  margin-top: 5px;
  word-break: break-word;
  white-space: normal;
  text-align: right;
  direction: rtl;
}

.Checkout-product-price {
  font-size: 14px;
  font-weight: 700;
  color: #5a38ff;
}

.Checkout-footer {
  position: sticky;
  bottom: 0;
  text-align: right;
  padding: 20px;
  border-radius: 8px;
  background-color: #fafafa;
  z-index: 10;
  width: 100%;
  border-radius: 12px;
  /* max-width: 320px; */
  margin-top: 20px;
  margin-bottom: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  font-family: "Rubik", sans-serif;
}

.Checkout-total {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #2b2d42;
  text-align: right;
  font-family: "Rubik", sans-serif;
}

.CoraPay-label-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  margin-bottom: 20px;
  margin-top: -24px;
}

.CoraPay-Summary {
  position: absolute;
  display: flex;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  padding: 10px 0;
  box-sizing: border-box;
  z-index: 1000;
  gap: 32px;
}

.Summary-title {
  font-size: 22px;
  font-weight: 700;
  color: #2b2d42;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Rubik", sans-serif;
}

.Summary-table {
  width: 100%;
  border-collapse: collapse;
  text-align: right;
  font-family: "Rubik", sans-serif;
  background-color: #fafafa;
  border-radius: 12px;
}

.Summary-table th {
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #2b2d42;
  border-radius: 12px;
  border-bottom: 1px solid #ddd;
}

.Summary-table td {
  padding: 10px;
  font-size: 14px;
  color: #555;
  border-radius: 12px;
}

.Summary-table tfoot td {
  font-size: 16px;
  font-weight: 600;
  color: #2b2d42;
  border-top: 1px solid #ddd;
}

.Note-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

/* Checkmark image */
.Note-image {
  width: 400px;
  height: 200px;
  object-fit: contain;
}

/* Title */
.Note-title {
  font-size: 20px;
  font-weight: 800;
  color: #01d2a4;
  margin-bottom: 10px;
  font-family: "Rubik", sans-serif;
}

.Failed-title {
  font-size: 20px;
  font-weight: 800;
  color: #ff005e;
  margin-bottom: 10px;
  font-family: "Rubik", sans-serif;
}

/* Message */
.Note-message {
  font-size: 10px;
  color: #555;
  margin-bottom: 20px;
  font-family: "Rubik", sans-serif;
  text-align: center;
}

.ForgetPass-header {
  font-size: 18px;
  font-weight: 800;
  color: #2b2d42;
  margin-bottom: 20px;
  text-align: center;
}

/* Information Text */
.ForgetPass-info {
  font-size: 14px;
  color: #666;
  text-align: center;
  margin-bottom: 10px;
  font-family: "Rubik", sans-serif;
}

/* Form Group */
.ForgetPass-form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 16px;
  font-family: "Rubik", sans-serif;
}

.ForgetPass-form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #2b2d42;
  margin-bottom: 4px;
  font-family: "Rubik", sans-serif;
}

.ForgetPass-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  text-align: right;
  font-family: "Rubik", sans-serif;
}

.ForgetPass-input:focus {
  border-color: #5a38ff;
  outline: none;
}

/* Button */
.ForgetPass-button {
  width: 100%;
  padding: 12px;
  background-color: #5a38ff;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  font-family: "Rubik", sans-serif;
}

.ForgetPass-button:hover {
  background-color: #472ec1;
}
